<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox">
      <div v-if="!form.id">
        <div class="title" @mousedown="move">
          <div>
            <span class="title-content">
              场站信息
            </span>
            <span class="caoz">
              <i
                class="iconfont icon-shanchu2"
                style="float: right; padding-right: 10px;"
                @click="colse()"
              ></i>
              <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
            </span>
          </div>
        </div>
        <div>
          <div class="Feature">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="110px"
              size="mini"
            >
            
            <el-form-item label="所属图层:" >
                <el-input
                  v-model="form.layerName"
                  class="selectWidth"
                  disabled
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="名称:" prop="stationName">
                <el-input
                  v-model="form.stationName"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入用户站名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="场站分类:" prop="stationKind">
                <el-select
                  v-model="form.stationKind"
                  class="selectWidth"
                  popper-class="gis-default"
                  placeholder="请选择场站分类"
                >
                  <el-option
                    v-for="item in stationKindList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="设计规模:">
                <el-input
                  v-model="form.scale"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入设计规模(Nm³/h)"
                >
                 <template slot="append">(Nm³/h)</template></el-input>
              </el-form-item> -->
              <el-form-item label="位置:">
                <el-input
                  v-model="form.address"
                  maxlength="100"
                  class="selectWidth"
                  placeholder="请输入位置"
                ></el-input>
              </el-form-item>
              <el-form-item label="经纬度:">
                <el-input
                  v-model="form.point"
                  class="selectWidth"
                  disabled
                  placeholder="请输入经纬度"
                ></el-input>
              </el-form-item>
              <!-- <div v-if="form.stationType=='2'">
              <el-form-item label="用户数量(户):">
                <el-input
                  v-model="form.userCount" type="number"
                  class="selectWidth"
                  placeholder="请输入用户数量"
                ></el-input>
              </el-form-item>
              <el-form-item label="居民(户):">
                <el-input
                  v-model="form.residentCount"
                  class="selectWidth"
                  placeholder="请输入居民" @change="getNUm()" type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="商业(户):">
                <el-input
                  v-model="form.businessCount"
                  class="selectWidth"
                  placeholder="请输入商业" @change="getNUm()" type="number"
                ></el-input>
              </el-form-item>
              <el-form-item label="工业(户):">
                <el-input
                  v-model="form.industryCount"
                  class="selectWidth"
                  placeholder="请输入工业" @change="getNUm()" type="number"
                ></el-input>
              </el-form-item>
              </div> -->
              <el-form-item label="备注:">
                <el-input
                  v-model="form.remark"
                  class="selectWidth"
                  type="textarea"
                  :rows="2"
                  maxlength="60"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="formbtn">
              <el-button size="mini" @click="colse">取消</el-button>
              <el-button size="mini" type="primary" @click="submit">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div @mousedown="move">
          <div style="width:760px;display:inline-block;position:relative;">
            <el-tabs ref="tabs" v-model="activeName" type="border-card">
              <el-tab-pane label="场站信息" name="one"></el-tab-pane>
            </el-tabs>
          </div>
          <span class="caozt">
            <i
              class="iconfont icon-shanchu2"
              style="float: right; padding-right: 10px;"
              @click="colse()"
            ></i>
            <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
          </span>
        </div>
        <div v-if="activeName == 'one'">
          <div v-if="!edit" class="Feature infof">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="110px"
            >
            <el-form-item label="所属图层:">
                {{ form.layerName }}
              </el-form-item>
              <el-form-item label="名称:">
                {{ form.stationName }}
              </el-form-item>
              <el-form-item label="场站分类:" >
                {{ form.stationKindName }}
              </el-form-item>
              <!-- <el-form-item label="设计规模:">
                {{ form.scale }}(Nm³/h)
              </el-form-item> -->
              <el-form-item label="位置:">
                {{ form.address }}
              </el-form-item>
              <el-form-item label="经纬度:">
                {{ form.point }}
              </el-form-item>
              <!-- <div v-if="form.stationType==2">
               <el-form-item label="用户数量(户):">
                {{ form.userCount }}
              </el-form-item>
              <el-form-item label="居民(户):">
                {{ form.residentCount }}
              </el-form-item>
              <el-form-item label="商业(户):">
               {{ form.businessCount }}
              </el-form-item>
              <el-form-item label="工业(户):">
                {{ form.industryCount }}
              </el-form-item>
              </div> -->
              <el-form-item label="备注:">
                {{ form.remark }}
              </el-form-item>
              
            </el-form>
            <div class="formbtn" v-if="!form.cz">
              <el-button size="mini"  @click="dialogVisible=false">取消</el-button>
              <el-button v-if="!form.active" size="mini" type="primary" @click="editStation()"
                >编辑</el-button
              >
            </div>
          </div>
          <div v-if="edit" class="Feature">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="100px"
              size="mini"
            >
              <el-form-item label="名称:" prop="stationName">
                <el-input
                  v-model="form.stationName"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="场站分类:" prop="stationKind">
                <el-select
                  v-model="form.stationKind"
                  class="selectWidth"
                  popper-class="gis-default"
                  placeholder="请选择场站分类"
                >
                  <el-option
                    v-for="item in stationKindList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="设计规模:">
                <el-input
                  v-model="form.scale"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入设计规模(Nm³/h)"
                >
                 <template slot="append">(Nm³/h)</template></el-input>
              </el-form-item> -->
              <el-form-item label="位置:">
                <el-input
                  v-model="form.address"
                  maxlength="100"
                  class="selectWidth"
                  placeholder="请输入位置"
                ></el-input>
              </el-form-item>
              <el-form-item label="经纬度:">
                <el-input
                  v-model="form.point"
                  class="selectWidth"
                  placeholder="请输入经纬度"
                ></el-input>
              </el-form-item>
              <!-- <div v-if="form.stationType=='2'">
              <el-form-item label="用户数量(户):">
                <el-input-number
                  v-model="form.userCount" type="number" :min="0"
                  class="selectWidth"
                  placeholder="请输入用户数量"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="居民(户):">
                <el-input-number
                  v-model="form.residentCount"
                  class="selectWidth"
                  placeholder="请输入居民" @change="getNUm()" type="number" :min="0"
                ></el-input-number>
              </el-form-item>
              <el-form-item label="商业(户):">
                <el-input-number
                  v-model="form.businessCount"
                  class="selectWidth"
                  placeholder="请输入商业" @change="getNUm()" type="number" :min="0"
                >
              </el-input-number>
              </el-form-item>
              <el-form-item label="工业(户):">
                <el-input-number
                  v-model="form.industryCount"
                  class="selectWidth"
                  placeholder="请输入工业" @change="getNUm()" type="number" :min="0"
                ></el-input-number>
              </el-form-item>
              </div> -->
              <el-form-item label="备注:">
                <el-input
                  v-model="form.remark"
                  class="selectWidth"
                  type="textarea"
                  :rows="2"
                  maxlength="60"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="formbtn">
              <el-button size="mini" @click="cancleEdit">取消</el-button>
              <el-button size="mini" type="primary" @click="submit">保存</el-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import '../Gis/style/skin.scss'
import { findDictTree } from '../Gis/apis/commonType'

import {
  stationFindByIds,
  saveStation,
  stationUpdate
} from '../Gis/apis/tool'
import { serverMap, } from '../Gis/utils/dict.js'
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: 'one',
      edit: true, //场站详情编辑
      form: {
        stationKind: '',
        businessCount:0,
        industryCount:0,
        residentCount:0,
        userCount:0,
        
      },
      stationKindList: [],
      channelTypeList: serverMap['1012'],
      rules: {
        stationName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        stationKind: [{ required: true, message: '请选择场站分类', trigger: 'change' }]
      },
      headerStyle: {
        color: '#fff',
        'background-color': '#244162',
        'border-right': 'rgba(110, 183, 227, 0.2) 1px solid',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      }
    }
  },
  mounted() {},
  methods: {
    delStation() {},
    getCodeName(type) {
      let code = type == 2 ? serverMap[1004][1].dictCode : serverMap[1004][0].dictCode
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.stationKindList = res.data[code]
          this.form.stationKind = this.stationKindList[0].dictCode
        }
      })
    },
    getNUm(){
      this.form.userCount = parseInt(this.form.businessCount)+parseInt(this.form.residentCount)+parseInt(this.form.industryCount)
    },
    openDialog(obj) {
      if (obj.id) {
        this.form = obj
        delete this.form.pointId
        this.edit = false
      } else {
        this.edit = true
        this.form.point = obj.point
        this.form.layerId = this.$store.getters.currentLayer
        this.form.layerName = this.$store.getters.currentLayerName
        
        this.form.pointId = obj.pointId
      }

      if (obj.equipmentType == 'userSta') {
        this.form.stationType = 2
        this.getCodeName(2)
      } else {
        this.form.stationType = 1

        this.getCodeName(1)
      }
      this.dialogVisible = true
    },
    setstationKindName(value) {
      this.stationKindList.forEach(item => {
        if (item.dictCode == value) {
          this.form.stationKindName = item.name
        }
      })
    },
    // 查看页到编辑页
    editStation() {
      if(this.form.stationKindName){
           let itemdebugger = this.stationKindList.find(
          el => el.name == this.form.stationKindName
        )
        if(itemdebugger){
          this.form.stationKind = itemdebugger.dictCode
        }
        
      }
      
      this.edit = true
    },
    // 新增、更新场站信息
    submit() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          that.setstationKindName(that.form.stationKind)
          if (that.form.id) {
            let lonlat = that.form.point.split(' ')
            if (lonlat && lonlat.length != 2) {
              that.$message.warning('请输入正确格式的经纬度')
              return
            }
            const reg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
            if (!reg.test(lonlat[0])) {
              that.$message.warning('请输入正确格式的经纬度')
              return
            }
            const reg1 = /^(\-|\+)?([0-8]?\d{1}\.\d{0,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
            if (!reg1.test(lonlat[1])) {
              console.log(222)
              that.$message.warning('请输入正确格式的经纬度')
              return
            }

            // 修改场站信息
            stationUpdate(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$parent.restSearch() 
                  that.$message.success(res.msg)
                  that.cancleEdit()
                  that.$eventBus.$emit('setFilter')
                } else {
                  that.$message.error(res.msg)
                }
              })
              // .catch(err => {
              //   that.$message.error(err)
              // })
          } else {
            // 新增场站信息
            saveStation(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$message.success(res.msg)
                  // that.form.id = res.data
                  that.getList()
                  that.$eventBus.$emit('setFilter')
                  that.cancleEdit()
                  that.dialogVisible = false
                  that.colse()
                } else {
                  that.$message.error(res.msg)
                }
              })
              // .catch(err => {
              //   that.$message.error(err)
              // })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 编辑页返回查看页
    cancleEdit() {
      this.edit = false
      this.getList()
    },
    // 获取场站详情进出点列表
    getList() {
      stationFindByIds({ id: this.form.id }).then(res => {
        let point = this.form.point
        this.form = res.data
        delete this.form.pointId
        this.form.point = point
      })
    },
    colse() {
      this.$parent.isSelectNode = false

      this.dialogVisible = false

      this.form = {}
      this.form.stationKind = this.stationKindList[0].dictCode
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.infof .el-form-item{margin-bottom:0}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
  background-color: var(--background-color-skin);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
  margin-bottom: 2px;
}
.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}

.layerBox {
  width: 760px;
  position: absolute;
  top: 4rem;
  left: 10rem;
  min-height: 3rem;
  min-width: 3rem;
  //   border: 1px solid var(--border-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  z-index: 2000;
  background: var(--background-color-skin);
  opacity: 0.98;
}
.Feature {
  padding: 5px 20px 10px;
  line-height: 0.28rem;
  // text-align: center;
  width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  text-align: right;
  margin-right: 30px;
  button {
    margin: 7px 0px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width: 380px;
}
.title {
  height: 40px;
  line-height: 40px;
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
::v-deep .el-tabs__nav-scroll {
  background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
  padding-left: 10px;
  padding-top: 6px;
}
::v-deep .el-tabs__item {
  height: 39px;
  line-height: 39px;
  text-align: center;
  min-width: 110px;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item.is-active {
  border-radius: 4px 4px 0px 0px;
  text-align: center;
  color: var(--font-color-f1);
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.title-content {
  color: var(--font-color-f1);
  font-weight: bold;
  padding: 13px 9px;
  font-size: 14px;
}
.caozt {
  position: absolute;
  right: 0px;
  top: 10px;
}
::v-deep .el-tabs--border-card {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}
::v-deep .el-form .el-select {
  width: 380px;
}
</style>
